import { mapActions } from "vuex";
import accordion from "./components/accordion/index.vue";

export default {
    name: "faqs",
    components: {
        accordion,
    },
    data() {
        return {
            faq: [],
        };
    },
    async created() {
        this.faq = await this.fetchFaq();
    },
    methods: {
        ...mapActions({
            fetchFaq: "pages/GET_FAQ",
        }),
    },
};
